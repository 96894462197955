import React, { useState, useEffect } from 'react'
import { CircularProgress, Button, Paper, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Title, Toast } from '../shared'
import * as API from '../../services/api'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
}))

export default function DirectCss(props) {
  const classes = useStyles()

  const [value, setValue] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (value === null) {
      API.get('settings/direct-css').then((response) => {
        setValue(response.data.style || '')
      })
    }
  }, [value])

  if (value === null) {
    return <CircularProgress />
  }

  const onChange = (event) => setValue(event.target.value)

  const onSubmit = async () => {
    const data = { style: value }

    setIsSubmitting(true)
    API.put('settings/direct-css', JSON.stringify(data))
      .then((response) => {
        setIsSubmitting(false)
        if (response?.data?.success) {
          setMessage('CSS updated')
        } else if (response?.message) {
          setMessage(response.message)
        }
      })
      .catch((error) => {
        setIsSubmitting(false)
        setMessage(error)
      })
  }

  return (
    <>
      <Title title="Custom Direct CSS">
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          onClick={onSubmit}
        >
          Save
        </Button>
      </Title>
      <Paper className={classes.container}>
        <TextField
          label="Custom CSS"
          value={value}
          onChange={onChange}
          variant="outlined"
          rows={28}
          fullWidth
          multiline
        />
      </Paper>
      <Toast message={message} setMessage={setMessage} />
    </>
  )
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    marginTop: theme.spacing(3),

    '& .MuiButtonBase-root': {
      minWidth: 30,
    },
  },
}))

export const Pagination = (props) => {
  const { page, perPage, total, gotoPage } = props
  const classes = useStyles()

  const pageCount = Math.ceil(total / perPage)

  if (pageCount <= 1) {
    return null
  }

  return (
    <Paper className={classes.pagination}>
      {[...Array(pageCount)].map((_, i) => (
        <Button
          key={i}
          size="small"
          color={i + 1 === page ? 'primary' : undefined}
          onClick={() => gotoPage(i + 1)}
        >
          {i + 1}
        </Button>
      ))}
    </Paper>
  )
}

import React, { useState, useEffect, useRef } from 'react'
import { FormControl, MenuItem, Grid, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FastField } from 'formik'
import { TextField, Select } from 'formik-material-ui'

let Jodit = null
let JoditEditor = null

export const defaultFormStyles = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  borderRadius: 4,
  boxShadow: '0 0 4px 0px rgba(0, 0, 0, 0.2)',

  '& h2': {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },

  '& .MuiTextField-root': {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  '& .MuiInputBase-root': {
    width: '100%',
  },

  '& .MuiButtonBase-root': {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  },

  '& .MuiLinearProgress-root': {
    height: 4,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 2,
    marginBottom: -6,
  },

  '& .MuiAutocomplete-root': {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  '& .MuiAutocomplete-root .MuiTextField-root': {
    margin: 0,
  },

  '& .MuiAutocomplete-root .MuiAutocomplete-endAdornment': {
    marginTop: -theme.spacing(2),
  },
})

const useDefaultFormStyles = makeStyles((theme) => ({
  form: { ...defaultFormStyles(theme) },

  defaultSelect: {
    '& .MuiFormLabel-root': {
      top: -4,
      left: 14,
    },
    '& .MuiInputBase-root': {
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& .MuiSelect-root': {
      padding: '10px 16px',
    },
  },
}))

export const PeriodField = (props) => {
  return (
    <Grid container>
      <Grid item sm={6} md={8}>
        <FastField
          component={TextField}
          variant="outlined"
          size="small"
          name={props.name}
          label={props.label}
        />
      </Grid>
      <Grid item sm={6} md={4}>
        <FormControl className={props.className}>
          <FastField
            component={Select}
            variant="outlined"
            name={props.name + 'Type'}
          >
            <MenuItem value="days">days</MenuItem>
            <MenuItem value="weeks">weeks</MenuItem>
            <MenuItem value="months">months</MenuItem>
          </FastField>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export const SelectField = (props) => {
  const { name, label, values, initialValue } = props

  const classes = useDefaultFormStyles()
  const id = name.toLowerCase().replace(/\W/g, '_').replace(/^_|_$/g, '')

  return (
    <FormControl className={classes.defaultSelect}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <FastField
        component={Select}
        className={classes.select}
        variant="outlined"
        size="small"
        name={name}
        label={label}
        defaultValue={initialValue}
        inputProps={{
          id,
        }}
      >
        {values.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </FastField>
    </FormControl>
  )
}

export const RichEditor = (props) => {
  const { initialValue, onChange } = props

  const editor = useRef()
  const [loaded, setLoaded] = useState(false)
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    if (!Jodit) {
      Jodit = require('jodit').Jodit
    }
    if (!JoditEditor) {
      JoditEditor = require('jodit-react').default
    }
    setLoaded(true)
  }, [setLoaded])

  if (!loaded) {
    return null
  }

  const buttons = [
    {
      'group': 'source',
      'buttons': [],
    },
    {
      'group': 'font-style',
      'buttons': [],
    },
    {
      name: 'code',
      iconURL: '/images/brackets.svg',
      tooltip: 'Insert Code Block',
      exec: function (editor) {
        editor.execCommand('formatBlock', false, 'code')
      },
      isActive: (editor, control) => {
        const current = editor.s.current()
        return Boolean(
          current && Jodit.modules.Dom.closest(current, 'code', editor.editor)
        )
      },
    },
    {
      'group': 'script',
      'buttons': [],
    },
    {
      'group': 'list',
      'buttons': ['ul', 'ol'],
    },
    {
      'group': 'indent',
      'buttons': [],
    },
    {
      'group': 'font',
      'buttons': [],
    },
    {
      'group': 'color',
      'buttons': [],
    },
    '\n',
    {
      'group': 'media',
      'buttons': [],
    },
    {
      'group': 'state',
      'buttons': [],
    },
    // {
    //   'group': 'clipboard',
    //   'buttons': [],
    // },
    {
      'group': 'insert',
      'buttons': [],
    },
    {
      'group': 'form',
      'buttons': [],
    },
    {
      'group': 'history',
      'buttons': [],
    },
    // {
    //   'group': 'search',
    //   'buttons': [],
    // },
    // {
    //   'group': 'other',
    //   'buttons': [],
    // },
    // {
    //   'group': 'info',
    //   'buttons': [],
    // },
  ]

  const config = {
    buttons,
    buttonsMD: buttons,
    buttonsSM: buttons,
    buttonsXS: buttons,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
  }

  const onEditorChange = (newValue) => {
    setValue(newValue)
    onChange(newValue)
  }

  return (
    <JoditEditor
      ref={editor}
      value={value}
      config={config}
      onBlur={onEditorChange}
    />
  )
}

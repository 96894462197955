import React, { useState, useEffect } from 'react'
import { CircularProgress, LinearProgress } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'gatsby-theme-material-ui'
import { Formik, Form, Field } from 'formik'
import { TextField, CheckboxWithLabel } from 'formik-material-ui'
import { defaultFormStyles } from './shared'
import { isEmail } from '../../services/utils'
import * as API from '../../services/api'

const useStyles = makeStyles((theme) => ({
  form: {
    ...defaultFormStyles(theme),

    '& .MuiCheckbox-root': {
      margin: theme.spacing(1),
    },
  },
}))

const defaultPermissions = {
  reports: false,
  offers: false,
  users: false,
  account: false,
  api: false,
}

export default function AdvertiserUserForm(props) {
  const { isNew, onSubmit } = props
  const initialValues = props.initialValues || {}
  const initialInfoValue = { ...initialValues.info }
  const initialPermissionsValue = {
    ...defaultPermissions,
    ...initialValues.permissions,
  }
  const classes = useStyles()

  const [advertisers, setAdvertisers] = useState(null)

  useEffect(() => {
    if (!advertisers) {
      API.all('advertisers', {
        page: 1,
        perPage: Number.MAX_SAFE_INTEGER,
      }).then((response) => {
        if (response?.data?.records) {
          setAdvertisers(response.data.records)
        }
      })
    }
  }, [advertisers])

  if (advertisers === null) {
    return <CircularProgress />
  }

  return (
    <Formik
      initialValues={{
        advertiserUuid: initialValues.advertiserUuid || '',
        name: initialValues.name || '',
        email: initialValues.email || '',
        password: '',
        confirmPassword: '',
        info: initialInfoValue,
        permissions: initialPermissionsValue,
      }}
      validate={(values) => {
        const errors = {}
        if (!values.email) {
          errors.email = 'Required'
        } else if (!isEmail(values.email)) {
          errors.email = 'Invalid email address'
        } else if (!values.name) {
          errors.name = 'Required'
        } else if (isNew && !values.password) {
          errors.password = 'Required'
        } else if (
          values.password &&
          values.password !== values.confirmPassword
        ) {
          errors.confirmPassword = "Passwords don't match"
        } else if (!values.advertiserUuid) {
          errors.advertiserUuid = 'Required'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue, submitForm, isSubmitting }) => (
        <Form className={classes.form} acceptCharset="UTF-8">
          <>
            <Autocomplete
              name="advertiserUuid"
              label="Advertiser"
              options={advertisers?.map((advertiser) => advertiser.uuid)}
              getOptionLabel={(value) => {
                const advertiser = advertisers.find(
                  (advertiser) => advertiser.uuid === value
                )
                return advertiser.name || 'Unnamed advertiser'
              }}
              defaultValue={initialValues?.advertiserUuid}
              onChange={(event, value) => {
                setFieldValue('advertiserUuid', value)
              }}
              renderInput={(inputProps) => (
                <Field
                  component={TextField}
                  name="advertiserUuid"
                  label="Advertiser"
                  {...inputProps}
                />
              )}
            />
            <Field
              component={TextField}
              name="name"
              type="text"
              label="Full name"
            />
            <Field
              component={TextField}
              name="email"
              type="email"
              label="Email"
            />
            <Field
              component={TextField}
              name="password"
              type="password"
              label={isNew ? 'Password' : 'New password'}
            />
            <Field
              component={TextField}
              name="confirmPassword"
              type="password"
              label={
                isNew ? 'Password confirmation' : 'New password confirmation'
              }
            />
          </>
          <br />
          <>
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="permissions.reports"
              onChange={(event) =>
                setFieldValue('permissions.reports', event.target.checked)
              }
              Label={{ label: 'Permission: Reporting' }}
            />
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="permissions.offers"
              onChange={(event) =>
                setFieldValue('permissions.offers', event.target.checked)
              }
              Label={{ label: 'Permission: Offer Management' }}
            />
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="permissions.users"
              onChange={(event) =>
                setFieldValue('permissions.users', event.target.checked)
              }
              Label={{ label: 'Permission: User Management' }}
            />
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="permissions.account"
              onChange={(event) =>
                setFieldValue('permissions.account', event.target.checked)
              }
              Label={{ label: 'Permission: Account Management' }}
            />
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="permissions.api"
              onChange={(event) =>
                setFieldValue('permissions.api', event.target.checked)
              }
              Label={{ label: 'Permission: API Access' }}
            />
          </>
          {isSubmitting && <LinearProgress />}
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {isNew ? 'Create User' : 'Update User'}
            </Button>
            <Button
              to={
                isNew
                  ? '/advertiser_users'
                  : `/advertiser_users/${initialValues.uuid}`
              }
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

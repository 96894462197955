import React from 'react'
import List from './List'
import Show from './Show'
import New from './New'
import Edit from './Edit'

export const createResource = (props) => {
  const { resource, title, listFields, showFields, details, form } = props
  const Details = details
  const Form = form

  return {
    List: (props) => (
      <List resource={resource} title={title} fields={listFields} {...props} />
    ),
    Show: (props) => (
      <Show
        resource={resource}
        title={title}
        fields={showFields}
        Details={Details}
        {...props}
      />
    ),
    New: (props) => (
      <New resource={resource} title={title} Form={Form} {...props} />
    ),
    Edit: (props) => (
      <Edit resource={resource} title={title} Form={Form} {...props} />
    ),
  }
}

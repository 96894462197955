import React, { useEffect, useState } from 'react'
import {
  Button as MaterialButton,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Button } from 'gatsby-theme-material-ui'
import { Title } from '../shared'
import * as API from '../../services/api'

const formatLabel = (label) => {
  if (label.startsWith('_')) {
    return ' '
  } else if (label.startsWith('!')) {
    return <b>{label.substring(1)}</b>
  } else {
    return label
  }
}

const formatValue = (field, record) => {
  if (typeof field === 'string') {
    return record[field]
  } else if (typeof field === 'function') {
    return field(record)
  }
}

export default function ResourceShow(props) {
  const { resource, uuid, title, fields, Details } = props

  const [record, setRecord] = useState(null)

  useEffect(() => {
    if (record === null) {
      API.one(resource, { uuid }).then((response) => {
        if (response) {
          setRecord(response.data.record)
        }
      })
    }
  }, [resource, record, uuid])

  if (record === null) {
    return <CircularProgress />
  }

  if (!record) {
    return (
      <>
        <Title title={title}>
          <Button color="primary" to={`/${resource}`}>
            Back
          </Button>
        </Title>
        <Typography align="center">Record not found</Typography>
      </>
    )
  }

  const deleteRecord = async () => {
    if (window.confirm('Are you sure?')) {
      await API.destroy(resource, uuid)
      window.location.assign(`/${resource}`)
    }
  }

  return (
    <>
      <Title title={title}>
        <Button color="primary" to={`/${resource}/${uuid}/edit`}>
          Edit
        </Button>
        <MaterialButton color="secondary" onClick={deleteRecord}>
          Delete
        </MaterialButton>
      </Title>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            {Object.keys(fields).map((field) => (
              <TableRow key={field}>
                <TableCell>
                  <>{formatLabel(field)}</>
                </TableCell>
                <TableCell>
                  <>{formatValue(fields[field], record)}</>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {Details && <Details record={record} />}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import {
  Button as MaterialButton,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'gatsby-theme-material-ui'
import { Title, Pagination } from '../shared'
import * as API from '../../services/api'
import { navigateTo, pluralized } from '../../services/utils'

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableRow-root': {
      cursor: 'pointer',
    },
  },
}))

const perPage = 1000

export default function ResourceList(props) {
  const { resource, title, fields } = props
  const classes = useStyles()

  const [records, setRecords] = useState(null)
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (records === null) {
      API.all(resource, { page, perPage }).then((response) => {
        if (response) {
          setRecords(response.data.records)
          setTotal(response.data.total)
        }
      })
    }
  }, [resource, records, page])

  if (records === null) {
    return <CircularProgress />
  }

  const gotoPage = (nextPage) => {
    setPage(nextPage)
    setRecords(null)
  }

  const deleteRecord = async (uuid) => {
    if (window.confirm('Are you sure?')) {
      await API.destroy(resource, uuid)
      setRecords(null)
    }
  }

  return (
    <>
      <Title title={pluralized(title)}>
        <Button color="primary" to={`/${resource}/new`}>
          New {title}
        </Button>
      </Title>

      <TableContainer component={Paper}>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {Object.keys(fields).map((field) => (
                <TableCell key={field}>{field}</TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records &&
              records.map((record) => (
                <TableRow
                  key={record.uuid}
                  onClick={(event) => {
                    if (!event.target.closest('a, button')) {
                      navigateTo(event, `/${resource}/${record.uuid}`)
                    }
                  }}
                >
                  {Object.keys(fields).map((field) =>
                    typeof fields[field] === 'string' ? (
                      <TableCell key={`${record.uuid}/${field}`}>
                        {record[fields[field]]}
                      </TableCell>
                    ) : (
                      <TableCell key={`${record.uuid}/${field}`}>
                        {fields[field](record)}
                      </TableCell>
                    )
                  )}
                  <TableCell align="right">
                    <Button
                      color="primary"
                      size="small"
                      to={`/${resource}/${record.uuid}/edit`}
                    >
                      Edit
                    </Button>
                    <MaterialButton
                      color="secondary"
                      size="small"
                      onClick={() => deleteRecord(record.uuid)}
                    >
                      Delete
                    </MaterialButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination {...{ page, perPage, gotoPage, total }} />
    </>
  )
}

import React from 'react'
import { LinearProgress, Grid } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'gatsby-theme-material-ui'
import { Formik, Form, Field, FastField } from 'formik'
import { TextField } from 'formik-material-ui'
import { defaultFormStyles } from './shared'
import { countryCodes, countryLabels } from '../../services/countries'

const useStyles = makeStyles((theme) => ({
  form: {
    ...defaultFormStyles(theme),

    '& .MuiCheckbox-root': {
      margin: theme.spacing(1),
    },
  },
}))

export default function AdvertiserForm(props) {
  const { isNew, onSubmit } = props

  const initialValues = props.initialValues || {}
  const initialCompanyValue = { ...initialValues.company }
  const initialBillingValue = { ...initialValues.billing }

  const classes = useStyles()

  return (
    <Formik
      initialValues={{
        name: initialValues.name || '',
        company: initialCompanyValue,
        billing: initialBillingValue,
        tuneAdvertiserId: initialValues.tuneAdvertiserId || '',
      }}
      validate={(values) => {
        const errors = {}
        if (!values.name) {
          errors.name = 'Required'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ setFieldValue, submitForm, isSubmitting, ...formik }) => (
        <Form className={classes.form} acceptCharset="UTF-8">
          <Field
            component={TextField}
            name="name"
            type="text"
            label="Advertiser name"
          />
          <FastField
            component={TextField}
            name="tuneAdvertiserId"
            label="Advertiser TUNE ID"
          />
          <br />

          <Grid container spacing={2}>
            <Grid item md={6}>
              <Field
                component={TextField}
                name="company.name"
                type="text"
                label="Company name"
                fullWidth
              />
              <Field
                component={TextField}
                name="company.address"
                type="text"
                label="Address"
                fullWidth
              />
              <Field
                component={TextField}
                name="company.city"
                type="text"
                label="City"
                fullWidth
              />
              <Field
                component={TextField}
                name="company.zip"
                type="text"
                label="Zipcode"
                fullWidth
              />
              <Autocomplete
                className={classes.autocompleteSelect}
                name="company.country"
                options={countryCodes}
                getOptionLabel={(value) => countryLabels[value] || ''}
                defaultValue={initialCompanyValue?.country}
                onChange={(event, value) => {
                  setFieldValue('company.country', value)
                }}
                renderInput={(inputProps) => (
                  <Field
                    component={TextField}
                    name="country"
                    label="Company country"
                    {...inputProps}
                  />
                )}
              />
              <Field
                component={TextField}
                name="company.vat"
                type="text"
                label="VAT number"
                fullWidth
              />
            </Grid>

            <Grid item md={6}>
              <Field
                component={TextField}
                name="billing.name"
                type="text"
                label="Billing company name"
                fullWidth
              />
              <Field
                component={TextField}
                name="billing.address"
                type="text"
                label="Billing address"
                fullWidth
              />
              <Field
                component={TextField}
                name="billing.city"
                type="text"
                label="Billing city"
                fullWidth
              />
              <Field
                component={TextField}
                name="billing.zip"
                type="text"
                label="Billing zipcode"
                fullWidth
              />
              <Autocomplete
                className={classes.autocompleteSelect}
                name="billing.country"
                options={countryCodes}
                getOptionLabel={(value) => countryLabels[value] || ''}
                defaultValue={initialBillingValue?.country}
                onChange={(event, value) => {
                  setFieldValue('billing.country', value)
                }}
                renderInput={(inputProps) => (
                  <Field
                    component={TextField}
                    name="country"
                    label="Billing country"
                    {...inputProps}
                  />
                )}
              />
              <Field
                component={TextField}
                name="billing.other"
                type="text"
                label="Details"
                fullWidth
              />
            </Grid>
          </Grid>
          <br />

          <Field
            component={TextField}
            name="company.officePhone"
            type="text"
            label="Office phone number"
          />
          <Field
            component={TextField}
            name="company.officeFax"
            type="text"
            label="Fax"
          />
          <br />

          {isSubmitting && <LinearProgress />}
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {isNew ? 'Create Advertiser' : 'Update Advertiser'}
            </Button>
            <Button
              to={isNew ? '/advertisers' : `/advertisers/${initialValues.uuid}`}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

import React, { useState } from 'react'
import moment from 'moment'
import md5 from 'md5'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Grid, Button, Typography, TextField } from '@material-ui/core'
import { PatientToast } from '../shared'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3),

    '& hr': {
      marginBottom: theme.spacing(4),
    },

    '& .MuiGrid-container': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },

  action: {
    marginBottom: theme.spacing(2),

    '& .MuiTextField-root': {
      marginLeft: theme.spacing(2),
    },

    '& .MuiInputBase-root': {
      height: 36,
    },
  },
}))

const PluginWordpress = (props) => {
  const { record } = props

  const classes = useStyles()

  const [host, setHost] = useState(`https://${record.domain}`)
  const [security, setSecurity] = useState('')
  const [version, setVersion] = useState('1.5')
  const [message, setMessage] = useState('')

  const handleChange = (set) => (event) => set(event.target.value)

  const httpHost = () => {
    if (host.indexOf('http') !== 0) {
      return `https://${host}`
    } else {
      return host
    }
  }

  const pluginAction = (path) => {
    window
      .fetch(`${httpHost()}${path}`, {
        mode: 'no-cors',
        cache: 'no-cache',
      })
      .then((response) => response.text())
      .then((response) => setMessage(response))
      .catch((error) => setMessage(`Error: ${String(error)}`))
  }

  const retrievePluginSecret = () => {
    const domain = host.replace(/https?:\/\//, '')
    const recoveryString = domain + '-' + moment().format('MM-DD-YYYY')
    const recovery = md5(recoveryString)

    const url = `${httpHost()}/heylink-plugin?recovery=${recovery}`
    window
      .fetch(url, {
        mode: 'no-cors',
        cache: 'no-cache',
      })
      .then((response) => response.text())
      .then((response) => setSecurity(response))
      .catch((error) => setMessage(`Error: ${String(error)}`))
  }

  const retrieveLastOrders = () => {
    if (!security) {
      return setMessage('Security key missing')
    }

    pluginAction(`/heylink-plugin/orders?secret=${security}`)
  }

  const updatePluginVersion = () => {
    if (!security) {
      return setMessage('Security key missing')
    }

    if (!version) {
      return setMessage('Plugin version missing')
    }

    pluginAction(`/heylink-plugin/update?version=${version}&secret=${security}`)
  }

  const updatePluginSecret = () => {
    if (!security) {
      return setMessage('Security key missing')
    }

    pluginAction(`/heylink-plugin/refresh-secret?secret=${security}`)
  }

  return (
    <>
      <Paper className={classes.container}>
        <Typography variant="h6">Plugin controls</Typography>
        <hr />

        <Grid container spacing={2}>
          <Grid item sm={12} md={6} lg={4}>
            <TextField
              size="small"
              variant="outlined"
              label="Host"
              value={host}
              onChange={handleChange(setHost)}
              fullWidth
            />
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <TextField
              size="small"
              variant="outlined"
              label="Security key"
              value={security}
              onChange={handleChange(setSecurity)}
              fullWidth
            />
          </Grid>
        </Grid>

        <div className={classes.action}>
          <Button
            variant="contained"
            color="primary"
            onClick={retrievePluginSecret}
          >
            Retrieve security key
          </Button>
        </div>

        <div className={classes.action}>
          <Button
            variant="contained"
            color="primary"
            onClick={retrieveLastOrders}
          >
            See last 10 orders
          </Button>
        </div>

        <div className={classes.action}>
          <Button
            variant="contained"
            color="primary"
            onClick={updatePluginVersion}
          >
            Update plugin
          </Button>
          <TextField
            size="small"
            variant="outlined"
            label="Version"
            placeholder="1.5"
            value={version}
            onChange={handleChange(setVersion)}
          />
        </div>

        <div className={classes.action}>
          <Button
            variant="contained"
            color="primary"
            onClick={updatePluginSecret}
          >
            Refresh secret key
          </Button>
        </div>
      </Paper>
      <PatientToast message={message} setMessage={setMessage} />
    </>
  )
}

export default function ContractDetails(props) {
  const { record } = props
  const cms = (record.data.cms || '').toLowerCase()

  switch (cms) {
    case 'wordpress':
      return <PluginWordpress record={record} />
    default:
      return null
  }
}

import React, { useState, useEffect } from 'react'
import {
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'gatsby-theme-material-ui'
import { Formik, Form, Field, FastField } from 'formik'
import { TextField, CheckboxWithLabel, Select } from 'formik-material-ui'
import moment from 'moment'
import { defaultFormStyles, PeriodField, SelectField } from './shared'
import { countryCodes, countryLabels } from '../../services/countries'
import { isEmail } from '../../services/utils'
import * as API from '../../services/api'

let Multiselect = null

const today = new Date()
const nextYear = today.setFullYear(today.getFullYear() + 1)

const userSort = (a, b) => a.publisherUuid - b.publisherUuid

const publisherOptions = (publishers, selected = undefined) =>
  publishers.reduce((result, publisher) => {
    if (
      (selected && selected.includes(publisher.uuid)) ||
      (!selected && !publisher.isAdmin)
    ) {
      result.push({
        name: publisher.name,
        uuid: publisher.uuid,
      })
    }
    return result
  }, [])

const defaultDataValues = {
  companyName: '',
  companyEmail: '',
  companyAddress: '',
  companyZip: '',
  companyCity: '',
  companyCountry: '',
  companyVat: '',
  sameBillingAddress: false,

  billingName: '',
  billingEmail: '',
  billingAddress: '',
  billingZip: '',
  billingCity: '',
  billingCountry: '',

  contactName: '',
  contactPosition: '',
  contactPhone: '',
  contactEmail: '',
  contactIp: '',

  managerName: 'Alexander Bogh Rasmussen',
  managerPosition: 'CEO',
  managerEmail: 'ab@Heylink.com',
  managerIp: '198.12.34.82',

  contractLength: moment(nextYear).format('MM/DD/YYYY'),
  initialFee: 10000,
  monthlyFee: 1000,
  currency: 'DKK',
  commissionModel: 'CPA',
  commissionPercentage: 10,
  commissionPrice: 100,
  billingType: 'heylink',
  transactionDeclinePeriod: '15',
  transactionDeclinePeriodType: 'days',
  invoicePeriod: '7',
  invoicePeriodType: 'days',
  trackingPeriod: '30',
  trackingPeriodType: 'days',
  trackingImplementation: 'Server-to-Server',

  cms: '',
  cmsCustom: '',

  productFeedAdded: false,
  trackingImplemented: false,
}

const statusOptions = [
  { label: 'Pending', value: 'pending' },
  { label: 'Contract sent', value: 'sent' },
  { label: 'Contract signed', value: 'signed' },
  { label: 'Tracking installed', value: 'installed' },
  { label: 'Running', value: 'running' },
]

export const currencyOptions = [
  { label: 'USD', value: 'USD' },
  { label: 'EUR', value: 'EUR' },
  { label: 'DKK', value: 'DKK' },
  { label: 'SEK', value: 'SEK' },
  { label: 'NOK', value: 'NOK' },
  { label: 'GBP', value: 'GBP' },
]

const commissionModelOptions = [
  { label: 'CPA', value: 'CPA' },
  { label: 'CPC', value: 'CPC' },
  { label: 'CPM', value: 'CPM' },
  { label: 'CPS', value: 'CPS' },
]

const billingTypeOptions = [
  { label: 'Billing via Heylink', value: 'heylink' },
  { label: 'Self billing', value: 'self' },
]

const trackingImplementationOptions = [
  { label: 'Server-to-Server', value: 'Server-to-Server' },
  { label: 'Client-to-Server', value: 'Client-to-Server' },
]

const tuneStatusOptions = [
  { label: 'Not created yet', value: 'initial' },
  { label: 'Failed to create', value: 'failed' },
  { label: 'Pending', value: 'pending' },
  { label: 'Active', value: 'active' },
  { label: 'Paused', value: 'paused' },
  { label: 'Deleted', value: 'deleted' },
]

const useStyles = makeStyles((theme) => ({
  form: {
    ...defaultFormStyles(theme),

    '& .MuiFormControl-root': {
      width: '100%',
    },

    '& .MuiFormControlLabel-root': {
      '& .MuiFormControlLabel-label': {
        paddingTop: theme.spacing(1),
      },
    },
  },
  fieldset: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  publishersSelect: {
    '& label': {
      left: 9,
      padding: '0 7px',
      background: '#fafafa',
      zIndex: 1,
    },
    '& .search-wrapper:hover': {
      borderColor: '#212121 !important',
    },
    '& li': {
      fontSize: '1rem',
    },
    '& li.highlight': {
      background: '#f5f5f5 !important',
    },
    '& li:hover': {
      background: '#f5f5f5 !important',
    },
  },
  statusSelect: {
    '& .MuiFormLabel-root.MuiInputLabel-shrink': {
      left: 14,
    },
    '& .MuiInputBase-root': {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    '& .MuiSelect-root': {
      padding: '10px 16px',
    },
  },
  tuneStatusSelect: {
    '& .MuiInputBase-root': {
      marginBottom: theme.spacing(4),
    },
  },
  dateTypeSelect: {
    '& .MuiInputBase-root': {
      width: 'calc(100% - 8px)',
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& .MuiSelect-root': {
      padding: '10px 16px 11px',
    },
  },
  autocompleteSelect: {
    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },

    '& .MuiAutocomplete-popupIndicator': {
      position: 'relative',
      top: 16,
      marginTop: 0,
      marginRight: 0,
    },
  },
}))

const multiSelectStyle = {
  multiselectContainer: {
    marginTop: 8,
    marginBottom: 8,
  },
  searchBox: {
    minHeight: 40,
    padding: '6px 12px',
    borderColor: '#c0c0c0',
  },
  inputField: {
    fontSize: '1rem',
  },
  chips: {
    borderRadius: 5,
    marginBottom: 2,
    fontSize: '12px',
    lineHeight: '14px',
  },
  optionContainer: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  option: {
    color: '#1c1c1c',
    background: '#ffffff',
    '&:hover': {
      background: '#e0e0e0',
    },
  },
}

export default function ContractForm(props) {
  const { onSubmit } = props

  const initialValues = props.initialValues || {}
  const initialDataValue = { ...defaultDataValues, ...initialValues.data }

  const uuid = initialValues.uuid

  const [users, setUsers] = useState(null)
  const [publishers, setPublishers] = useState(null)
  const [advertisers, setAdvertisers] = useState(null)

  useEffect(() => {
    if (!Multiselect) {
      Multiselect = require('multiselect-react-dropdown').Multiselect
    }
  })

  useEffect(() => {
    if (!users && !publishers && !advertisers) {
      API.form('contracts').then((response) => {
        if (response?.data?.users) {
          setUsers(response.data.users)
        }
        if (response?.data?.publishers) {
          setPublishers(response.data.publishers)
        }
        if (response?.data?.advertisers) {
          setAdvertisers(response.data.advertisers)
        }
      })
    }
  }, [users, publishers, advertisers])

  if (users === null || publishers === null || advertisers === null) {
    return <CircularProgress />
  }

  return (
    <Formik
      initialValues={{
        publisherUserUuid: initialValues.publisherUserUuid || '',
        publisherUuids: initialValues.publisherUuids || [],
        advertiserUuid: initialValues.advertiserUuid || '',
        isProduction: !!initialValues.isProduction,
        status: initialValues.status || 'pending',
        code: initialValues.code || '',
        url: initialValues.url || '',
        data: initialDataValue,
        tuneStatus: initialValues.tuneStatus || 'initial',
        tuneAdvertiserId: initialValues.tuneAdvertiserId || '',
        tuneOfferId: initialValues.tuneOfferId || '',
      }}
      validate={(values) => {
        const errors = {}
        if (!values.publisherUserUuid) {
          errors.publisherUserUuid = 'Required'
        } else if (!values.advertiserUuid) {
          errors.advertiserUuid = 'Required'
        } else if (!values.code) {
          errors.code = 'Required'
        } else if (
          values.data.companyEmail &&
          !isEmail(values.data.companyEmail)
        ) {
          errors.data = { companyEmail: 'Invalid email address' }
        } else if (
          values.data.billingEmail &&
          !isEmail(values.data.billingEmail)
        ) {
          errors.data = { billingEmail: 'Invalid email address' }
        } else if (
          values.data.managerEmail &&
          !isEmail(values.data.managerEmail)
        ) {
          errors.data = { managerEmail: 'Invalid email address' }
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values)
        setSubmitting(false)
      }}
    >
      {(formik) => (
        <ContractsFormFields
          {...props}
          {...formik}
          {...{ uuid, users, publishers, advertisers }}
        />
      )}
    </Formik>
  )
}

const ContractsFormFields = (props) => {
  const { uuid, isNew, users, publishers, advertisers } = props
  const { values, setFieldValue, submitForm, isSubmitting } = props
  const classes = useStyles()

  useEffect(() => {
    if (values.data.sameBillingAddress) {
      setFieldValue('data.billingName', values.data.companyName)
      setFieldValue('data.billingEmail', values.data.companyEmail)
      setFieldValue('data.billingAddress', values.data.companyAddress)
      setFieldValue('data.billingZip', values.data.companyZip)
      setFieldValue('data.billingCity', values.data.companyCity)
      setFieldValue('data.billingCountry', values.data.companyCountry)
    }
  }, [
    values.data.sameBillingAddress,
    values.data.companyName,
    values.data.companyEmail,
    values.data.companyAddress,
    values.data.companyZip,
    values.data.companyCity,
    values.data.companyCountry,
    setFieldValue,
  ])

  return (
    <Form className={classes.form} acceptCharset="UTF-8">
      <Grid container justify="center" alignItems="center">
        <Grid item sm={12} md={9}>
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="isProduction"
            onChange={(event) =>
              setFieldValue('isProduction', event.target.checked)
            }
            Label={{
              label: 'Allow changing TUNE records (debug)',
            }}
          />
          <Autocomplete
            className={classes.autocompleteSelect}
            name="publisherUserUuid"
            size="small"
            options={users?.sort(userSort).map((user) => user.uuid)}
            getOptionLabel={(value) => {
              const user = users.find((user) => user.uuid === value)
              if (user) {
                return `(${user?.publisher?.name}) ${user?.name}`
              } else {
                return ''
              }
            }}
            defaultValue={props.initialValues?.publisherUserUuid}
            onChange={(event, value) => {
              setFieldValue('publisherUserUuid', value)
            }}
            renderInput={(inputProps) => (
              <Field
                component={TextField}
                variant="outlined"
                size="small"
                name="publisherUserUuid"
                label="Publisher user"
                {...inputProps}
              />
            )}
          />
          <FormControl className={classes.publishersSelect}>
            <InputLabel shrink={true}>Additional publisher access</InputLabel>
            <FastField
              component={Multiselect}
              variant="outlined"
              size="small"
              name="publisherUuids"
              options={publisherOptions(publishers)}
              selectedValues={publisherOptions(
                publishers,
                values.publisherUuids
              )}
              displayValue="name"
              onSelect={(values) => {
                setFieldValue(
                  'publisherUuids',
                  values.map((value) => value.uuid)
                )
              }}
              onRemove={(values) => {
                setFieldValue(
                  'publisherUuids',
                  values.map((value) => value.uuid)
                )
              }}
              style={multiSelectStyle}
            />
          </FormControl>
          <Autocomplete
            className={classes.autocompleteSelect}
            name="advertiserUuid"
            size="small"
            options={advertisers.map((advertiser) => advertiser.uuid)}
            getOptionLabel={(value) => {
              const advertiser = advertisers.find(
                (advertiser) => advertiser.uuid === value
              )
              if (advertiser) {
                return `${advertiser?.name}`
              } else {
                return ''
              }
            }}
            defaultValue={props.initialValues?.advertiserUuid}
            onChange={(event, value) => {
              setFieldValue('advertiserUuid', value)
            }}
            renderInput={(inputProps) => (
              <Field
                component={TextField}
                variant="outlined"
                size="small"
                name="advertiserUuid"
                label="Advertiser"
                {...inputProps}
              />
            )}
          />
          <FormControl className={classes.statusSelect}>
            <InputLabel htmlFor="contract-status">Current status</InputLabel>
            <FastField
              component={Select}
              className={classes.select}
              variant="outlined"
              size="small"
              name="status"
              label="Current status"
              defaultValue={props.initialValues.status}
              inputProps={{
                id: 'contract-status',
              }}
            >
              {statusOptions.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </FastField>
          </FormControl>

          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="data.productFeedAdded"
            onChange={(event) =>
              setFieldValue('data.productFeedAdded', event.target.checked)
            }
            Label={{
              label: `Product feed added`,
            }}
          />
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="data.trackingImplemented"
            onChange={(event) =>
              setFieldValue('data.trackingImplemented', event.target.checked)
            }
            Label={{
              label: `${values.data?.trackingImplementation} is installed`,
            }}
          />
        </Grid>
      </Grid>

      <br />

      <Grid container justify="center" alignItems="center">
        <Grid item sm={12} md={9}>
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="code"
            label="Advertiser code"
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="url"
            label="Advertiser url"
          />
        </Grid>
      </Grid>

      <Typography variant="h6" className={classes.fieldset}>
        Company details
      </Typography>
      <Grid container justify="center" alignItems="center">
        <Grid item sm={12} md={9}>
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="data.companyName"
            label="Company name"
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            type="email"
            name="data.companyEmail"
            label="Company email"
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="data.companyAddress"
            label="Company address"
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="data.companyZip"
            label="Company zip"
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="data.companyCity"
            label="Company city"
          />
          <Autocomplete
            className={classes.autocompleteSelect}
            name="data.companyCountry"
            size="small"
            options={countryCodes}
            getOptionLabel={(value) => countryLabels[value] || ''}
            defaultValue={props.initialValues?.data?.companyCountry}
            onChange={(event, value) => {
              setFieldValue('data.companyCountry', value)
            }}
            renderInput={(inputProps) => (
              <Field
                component={TextField}
                variant="outlined"
                size="small"
                name="data.companyCountry"
                label="Company country"
                {...inputProps}
              />
            )}
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="data.companyVat"
            label="Company vat"
          />
          <Field
            component={CheckboxWithLabel}
            variant="outlined"
            size="small"
            type="checkbox"
            name="data.sameBillingAddress"
            Label={{ label: 'Same billing address' }}
          />
        </Grid>
      </Grid>

      <Collapse in={!values.data.sameBillingAddress}>
        <Typography variant="h6" className={classes.fieldset}>
          Billing details
        </Typography>
        <Grid container justify="center" alignItems="center">
          <Grid item sm={12} md={9}>
            <Field
              component={TextField}
              variant="outlined"
              size="small"
              name="data.billingName"
              label="Billing name"
              disabled={values.data.sameBillingAddress}
            />
            <Field
              component={TextField}
              variant="outlined"
              size="small"
              type="email"
              name="data.billingEmail"
              label="Billing email"
              disabled={values.data.sameBillingAddress}
            />
            <Field
              component={TextField}
              variant="outlined"
              size="small"
              name="data.billingAddress"
              label="Billing address"
              disabled={values.data.sameBillingAddress}
            />
            <Field
              component={TextField}
              variant="outlined"
              size="small"
              name="data.billingZip"
              label="Billing zip"
              disabled={values.data.sameBillingAddress}
            />
            <Field
              component={TextField}
              variant="outlined"
              size="small"
              name="data.billingCity"
              label="Billing city"
              disabled={values.data.sameBillingAddress}
            />
            <Autocomplete
              className={classes.autocompleteSelect}
              name="data.billingCountry"
              size="small"
              options={countryCodes}
              getOptionLabel={(value) => countryLabels[value] || ''}
              defaultValue={props.initialValues.data.billingCountry}
              disabled={values.data.sameBillingAddress}
              onChange={(event, value) => {
                setFieldValue('data.billingCountry', value)
              }}
              renderInput={(inputProps) => (
                <Field
                  component={TextField}
                  variant="outlined"
                  size="small"
                  name="data.billingCountry"
                  label="Company country"
                  {...inputProps}
                />
              )}
            />
          </Grid>
        </Grid>
      </Collapse>

      <Typography variant="h6" className={classes.fieldset}>
        Contact person
      </Typography>
      <Grid container justify="center" alignItems="center">
        <Grid item sm={12} md={9}>
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="data.contactName"
            label="Contact name"
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="data.contactPosition"
            label="Contact position"
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="data.contactPhone"
            label="Contact phone"
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            type="email"
            name="data.contactEmail"
            label="Contact email"
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            type="email"
            name="data.contactIp"
            label="Contact IP"
          />
        </Grid>
      </Grid>

      <Typography variant="h6" className={classes.fieldset}>
        Manager
      </Typography>
      <Grid container justify="center" alignItems="center">
        <Grid item sm={12} md={9}>
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="data.managerName"
            label="Signing name"
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="data.managerPosition"
            label="Signing position"
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            type="email"
            name="data.managerEmail"
            label="Signing email"
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            type="email"
            name="data.managerIp"
            label="Signing IP"
          />
        </Grid>
      </Grid>

      <Typography variant="h6" className={classes.fieldset}>
        Contract details
      </Typography>
      <Grid container justify="center" alignItems="center">
        <Grid item sm={12} md={9}>
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="data.contractLength"
            label="Contract length"
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="data.initialFee"
            label="Initial fee"
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="data.monthlyFee"
            label="Monthly fee"
          />
          <SelectField
            name="data.currency"
            label="Currency"
            initialValue={props.initialValues.data?.currency}
            values={currencyOptions}
          />
          <SelectField
            name="data.commissionModel"
            label="Commission model"
            initialValue={props.initialValues.data?.commissionModel}
            values={commissionModelOptions}
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="data.commissionPercentage"
            label="Commission percentage (CPS)"
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="data.commissionPrice"
            label="Commission price (CPA, CPC, CPM)"
          />
          <SelectField
            name="data.billingType"
            label="Billing type"
            initialValue={props.initialValues.data?.billingType}
            values={billingTypeOptions}
          />

          <PeriodField
            className={classes.dateTypeSelect}
            name="data.transactionDeclinePeriod"
            label="Transaction decline period"
          />
          <PeriodField
            className={classes.dateTypeSelect}
            name="data.invoicePeriod"
            label="Invoice period"
          />
          <PeriodField
            className={classes.dateTypeSelect}
            name="data.trackingPeriod"
            label="Tracking period"
          />

          <SelectField
            name="data.trackingImplementation"
            label="Tracking implementation"
            initialValue={props.initialValues.data?.trackingImplementation}
            values={trackingImplementationOptions}
          />
        </Grid>
      </Grid>

      <Typography variant="h6" className={classes.fieldset}>
        Other
      </Typography>
      <Grid container justify="center" alignItems="center">
        <Grid item sm={12} md={9}>
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="data.cms"
            label="CMS"
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="data.cmsCustom"
            label="CMS Other"
          />
        </Grid>
      </Grid>

      <Typography variant="h6" className={classes.fieldset}>
        TUNE
      </Typography>
      <Grid container justify="center" alignItems="center">
        <Grid item sm={12} md={9}>
          <FormControl
            className={[classes.statusSelect, classes.tuneStatusSelect].join(
              ' '
            )}
          >
            <InputLabel htmlFor="contract-tune-status">
              Current TUNE status (don't affect actual Offer status)
            </InputLabel>
            <FastField
              component={Select}
              className={classes.select}
              variant="outlined"
              size="small"
              name="tuneStatus"
              label="Current TUNE status (don't affect actual Offer status)"
              defaultValue={props.initialValues.tuneStatus}
              inputProps={{
                id: 'contract-tune-status',
              }}
            >
              {tuneStatusOptions.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </FastField>
          </FormControl>
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="tuneAdvertiserId"
            label="Advertiser ID"
          />
          <FastField
            component={TextField}
            variant="outlined"
            size="small"
            name="tuneOfferId"
            label="Offer ID"
          />
        </Grid>
      </Grid>

      <br />
      {isSubmitting && <LinearProgress />}
      <div className={classes.formActions}>
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          onClick={submitForm}
        >
          {isNew ? 'Create Campaign' : 'Update Campaign'}
        </Button>
        <Button to={isNew ? '/contracts' : `/contracts/${uuid}`}>Cancel</Button>
      </div>
    </Form>
  )
}

import React from 'react'
import { Router, Redirect } from '@reach/router'
import { compact } from 'lodash'
import { getCountryName } from '../services/countries'
import {
  formatDate,
  formatDatetimeSec,
  pluralize,
  contractStatus,
  contractPartnership,
  contractCms,
  contractTuneStatus,
  articleType,
  articlePath,
} from '../services/utils'
import { createResource } from '../components/resources'
import { createCallbackResource } from '../components/callbacks'
import Layout from '../components/layout/Layout'
import ContractDetails from '../components/details/Contract'
import AdminUserForm from '../components/forms/AdminUser'
import PublisherForm from '../components/forms/Publisher'
import PublisherUserForm from '../components/forms/PublisherUser'
import ContractForm from '../components/forms/Contract'
import AdvertiserForm from '../components/forms/Advertiser'
import AdvertiserUserForm from '../components/forms/AdvertiserUser'
import ArticleForm from '../components/forms/Article'
import ConversionRuleForm from '../components/forms/ConversionRule'
import Settings from '../components/settings'

import '../../static/styles.css'

const AdminUsers = createResource({
  resource: 'admin_users',
  title: 'Admin User',
  listFields: {
    'Email': 'email',
    'Created at': (record) => formatDate(record.createdAt),
  },
  showFields: {
    'Internal ID': 'uuid',
    'Email': 'email',
    'Created at': (record) => formatDate(record.createdAt),
    'Updated at': (record) => formatDate(record.updatedAt),
  },
  form: AdminUserForm,
})

const Publishers = createResource({
  resource: 'publishers',
  title: 'Publisher',
  listFields: {
    'Name': 'name',
    'TUNE ID': 'tuneAffiliateId',
    'Admin': (record) => (record.isAdmin ? 'Admin' : ''),
    'Created at': (record) => formatDate(record.createdAt),
  },
  showFields: {
    'Internal ID': 'uuid',
    'Name': 'name',
    'Description': (record) => record.description,
    'Admin': (record) => (record.isAdmin ? 'Yes' : ''),
    'Listed source': (record) => (record.isListedSource ? 'Yes' : ''),
    'Listed source attraction': (record) => record.sourceAttraction,
    'Logo': (record) => {
      if (record.logoUrl) {
        return (
          <img
            src={record.logoUrl}
            alt="Publisher logo"
            style={{ width: 120, height: 50, objectFit: 'contain' }}
          />
        )
      } else {
        return null
      }
    },
    'Users': (record) => pluralize(record.users.length, 'user'),
    'Partner ID': (record) => record.tuneAffiliateId,
    'Created at': (record) => formatDate(record.createdAt),
    'Updated at': (record) => formatDate(record.updatedAt),
  },
  form: PublisherForm,
})

const PublisherUsers = createResource({
  resource: 'publisher_users',
  title: 'Publisher User',
  listFields: {
    'Publisher': (record) => record.publisher?.name || 'No publisher',
    'Name': 'name',
    'Email': 'email',
    'Created at': (record) => formatDate(record.createdAt),
  },
  showFields: {
    'Internal ID': 'uuid',
    'Publisher': (record) => record.publisher?.name || 'No publisher',
    'Name': 'name',
    'Email': 'email',
    'Created at': (record) => formatDate(record.createdAt),
    'Updated at': (record) => formatDate(record.updatedAt),
  },
  form: PublisherUserForm,
})

const Contracts = createResource({
  resource: 'contracts',
  title: 'Campaign',
  listFields: {
    'Code': 'code',
    'Publisher': (record) => record.publisher?.name,
    'Advertiser': (record) => record.advertiser?.name,
    'Status': (record) => contractStatus(record),
    'TUNE ID': 'tuneOfferId',
    'Tune status': (record) => contractTuneStatus(record),
    'Contact Name': (record) => record.data.contactName,
    'Created at': (record) => formatDate(record.createdAt),
  },
  showFields: {
    'Internal ID': 'uuid',
    'Advertiser code': 'code',
    'Advertiser URL': 'url',
    'Advertiser domain': 'domain',
    'Campaign code': 'shortlinkCode',
    'Campaign tracking url': 'trackingUrl',
    'Status': (record) => contractStatus(record, true),
    'Publisher': (record) => (
      <a href={`/publishers/${record.publisher?.uuid}`}>
        {record.publisher?.name}
      </a>
    ),
    'Advertiser': (record) => (
      <a href={`/advertisers/${record.advertiser?.uuid}`}>
        {record.advertiser?.name}
      </a>
    ),
    'Owner': (record) => (
      <a href={`/publisher_users/${record.user?.uuid}`}>{record.user?.name}</a>
    ),
    'Publisher access': (record) =>
      record.publishers.map((publisher) => publisher.name).join(', ') ||
      'Owner only',

    '!Links': '',
    'Agreement PDF': (record) => {
      const url = `${
        process.env.BACKEND_HOST
      }/connect/v1/contract/${encodeURIComponent(record.code)}.pdf`
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      )
    },
    'Connect Signup': (record) => {
      const url = `${process.env.CONNECT_HOST}/signup?code=${encodeURIComponent(
        record.code
      )}`
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      )
    },
    'Connect CMS install': (record) => {
      const url = `${
        process.env.CONNECT_HOST
      }/signup/platforms?code=${encodeURIComponent(record.code)}`
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      )
    },

    '!Company': '',
    'Company name': (record) => record.data.companyName,
    'Company email': (record) => record.data.companyEmail,
    'Company address': (record) =>
      compact([
        record.data.companyAddress,
        record.data.companyZip,
        record.data.companyCity,
        getCountryName(record.data.companyCountry),
      ]).join(', '),
    'Company VAT': (record) => record.data.companyVat,
    'Same billing address': (record) =>
      record.data.sameBillingAddress ? 'Yes' : 'No',

    '!Billing': '',
    'Billing name': (record) => record.data.billingName,
    'Billing email': (record) => record.data.billingEmail,
    'Billing address': (record) =>
      compact([
        record.data.billingAddress,
        record.data.billingZip,
        record.data.billingCity,
        getCountryName(record.data.billingCountry),
      ]).join(', '),

    '!Contact person': '',
    'Contact name': (record) => record.data.contactName,
    'Contact position': (record) => record.data.contactPosition,
    'Contact phone': (record) => record.data.contactPhone,
    'Contact email': (record) => record.data.contactEmail,
    'Signature': (record) => record.data.signature,
    'Signature IP': (record) => record.data.contactIp,

    '!Manager': '',
    'Manager name': (record) => record.data.managerName,
    'Manager position': (record) => record.data.managerPosition,
    'Manager email': (record) => record.data.managerEmail,
    'Manager IP': (record) => record.data.managerIp,

    '!Choices': '',
    'Partnership': (record) => contractPartnership(record.data.partnership),
    'CMS': (record) => contractCms(record.data.cms, record.data.cmsCustom),
    'Have accepted terms': (record) => (record.data.acceptTerms ? 'Yes' : 'No'),
    'Have finished form': (record) => (record.formFinished ? 'Yes' : 'No'),

    '!Contract details': '',
    'Contract length': (record) => formatDate(record.data.contractLength),
    'Initial fee': (record) =>
      `${record.data.currency} ${record.data.initialFee}`,
    'Monthly fee': (record) =>
      `${record.data.currency} ${record.data.monthlyFee}`,
    'Invoice period': (record) =>
      `${record.data.invoicePeriod} ${record.data.invoicePeriodType}`,
    'Commission model': (record) => record.data.commissionModel,
    'Commission percentage': (record) => `${record.data.commissionPercentage}%`,
    'Commission price': (record) =>
      `${record.data.currency} ${record.data.commissionPrice}`,
    'Commission renegotiation period': (record) =>
      `${record.data.commissionRenegotiationPeriod} ${record.data.commissionRenegotiationPeriodType}`,
    'Transaction registration period': (record) =>
      `${record.data.transactionRegistrationPeriod} ${record.data.transactionRegistrationPeriodType}`,
    'Transaction decline period': (record) =>
      `${record.data.transactionDeclinePeriod} ${record.data.transactionDeclinePeriodType}`,
    'Tracking period': (record) =>
      `${record.data.trackingPeriod} ${record.data.trackingPeriodType}`,
    'Tracking implementation': (record) => record.data.trackingImplementation,
    'Tracking installed': (record) =>
      record.data.trackingImplemented ? 'Yes' : '',

    '!Tune': '',
    'Tune status': (record) => contractTuneStatus(record),
    'Advertiser ID': 'tuneAdvertiserId',
    'Offer ID': 'tuneOfferId',
    'Current TUNE advertiser record': (record) => {
      if (record.data.tuneAdvertiser) {
        return <pre>{JSON.stringify(record.data.tuneAdvertiser, null, 2)}</pre>
      }
    },
    'Current TUNE offer record': (record) => {
      if (record.data.tuneOffer) {
        return <pre>{JSON.stringify(record.data.tuneOffer, null, 2)}</pre>
      }
    },

    '_0': '',
    'Created at': (record) => formatDate(record.createdAt),
    'Updated at': (record) => formatDate(record.updatedAt),
  },
  details: ContractDetails,
  form: ContractForm,
})

const Advertisers = createResource({
  resource: 'advertisers',
  title: 'Advertiser',
  listFields: {
    'Name': 'name',
    'TUNE ID': 'tuneAdvertiserId',
    'Created at': (record) => formatDate(record.createdAt),
  },
  showFields: {
    'Internal ID': 'uuid',
    'Advertiser TUNE ID': 'tuneAdvertiserId',
    'Name': 'name',
    'Company info': (record) => JSON.stringify(record.company),
    'Billing info': (record) => JSON.stringify(record.billing),
    'Users': (record) => pluralize(record.users.length, 'user'),
    'Created at': (record) => formatDate(record.createdAt),
    'Updated at': (record) => formatDate(record.updatedAt),
  },
  form: AdvertiserForm,
})

const AdvertiserUsers = createResource({
  resource: 'advertiser_users',
  title: 'Advertiser User',
  listFields: {
    'Advertiser': (record) => record.advertiser?.name || 'No advertiser',
    'Name': 'name',
    'Email': 'email',
    'Created at': (record) => formatDate(record.createdAt),
  },
  showFields: {
    'Internal ID': 'uuid',
    'Advertiser': (record) => record.advertiser?.name || 'No advertiser',
    'Name': 'name',
    'Email': 'email',
    'Created at': (record) => formatDate(record.createdAt),
    'Updated at': (record) => formatDate(record.updatedAt),
  },
  form: AdvertiserUserForm,
})

const Articles = createResource({
  resource: 'articles',
  title: 'Help Article',
  listFields: {
    'Published': (record) => (record.isPublished ? 'Yes' : ''),
    'Category': (record) => articleType(record),
    'Slug': 'slug',
    'Headline': 'title',
    'Last update at': (record) => formatDate(record.updatedAt),
    'Created at': (record) => formatDate(record.createdAt),
  },
  showFields: {
    'Internal ID': 'uuid',
    'Published': (record) => (record.isPublished ? 'Yes' : ''),
    'Category': (record) => articleType(record),
    'Slug': 'slug',
    'Path': (record) => articlePath(record),
    'Logo': (record) => {
      if (record.logoUrl) {
        return (
          <img
            src={record.logoUrl}
            alt="Major CMS logo"
            style={{ width: 120, height: 120, objectFit: 'contain' }}
          />
        )
      } else {
        return null
      }
    },
    'Headline': 'title',
    'Subheadline': 'subtitle',
    'Body': (record) => (
      <div
        className="article-preview"
        dangerouslySetInnerHTML={{ __html: record.body }}
      />
    ),
    'Created at': (record) => formatDate(record.createdAt),
    'Updated at': (record) => formatDate(record.updatedAt),
  },
  form: ArticleForm,
})

const ConversionRules = createResource({
  resource: 'conversion_rules',
  title: 'Conversion Rule',
  listFields: {
    'Contract': (record) => record.contract.code,
    'Name': 'name',
    'Active': (record) => (record.isActive ? 'Yes' : ''),
  },
  showFields: {
    'Contract': (record) => record.contract.code,
    'Name': 'name',
    'Active': (record) => (record.isActive ? 'Yes' : ''),
    'Rule': (record) => {
      if (record.rule) {
        return <pre>{JSON.stringify(record.rule, null, 2)}</pre>
      }
    },
  },
  form: ConversionRuleForm,
})

const Callbacks = createCallbackResource({
  resource: 'callbacks',
  title: 'Callback',
  listFields: {
    'HLTID': (record) => {
      if (record.callbackConversionError) {
        return `⚠️${record.callbackHltid}`
      } else {
        return record.callbackHltid
      }
    },
    'Order ID': 'callbackOrderId',
    'Offer ID': 'callbackOfferId',
    'Goal': (record) => `${record.callbackGoalId} - ${record.callbackGoalName}`,
    'Marketing tags': (record) => {
      if (record.callbackMarketingTags) {
        return record.callbackMarketingTags.length
      } else {
        return '-'
      }
    },
    'Total': (record) =>
      `${record.callbackOrderCurrencyCode || 'DKK'} ${
        record.callbackOrderSubTotal || record.callbackOrderTotal
      }`,
    'Created at': (record) => formatDatetimeSec(record.callbackCreatedDatetime),
  },
  showFields: {
    'UUID': 'callbackUuid',
    'HLTID': (record) => {
      if (record.callbackConversionError) {
        return `⚠️${record.callbackHltid}`
      } else {
        return record.callbackHltid
      }
    },
    'Order ID': 'callbackOrderId',
    'Offer ID': 'callbackOfferId',
    'Coupon code': 'callbackOrderCouponCode',
    'Goal': (record) => `${record.callbackGoalId} - ${record.callbackGoalName}`,
    'Currency': 'callbackOrderCurrencyCode',
    'Items': (record) => {
      if (record.callbackOrderItems) {
        return <pre>{JSON.stringify(record.callbackOrderItems, null, 2)}</pre>
      }
    },
    'Total': (record) =>
      `${record.callbackOrderCurrencyCode} ${record.callbackOrderTotal}`,
    'SubTotal': (record) =>
      `${record.callbackOrderCurrencyCode} ${record.callbackOrderSubTotal}`,
    'Tax': (record) =>
      `${record.callbackOrderCurrencyCode} ${record.callbackOrderTax}`,
    'Shipping': (record) =>
      `${record.callbackOrderCurrencyCode} ${record.callbackOrderShipping}`,
    'Discount': (record) =>
      `${record.callbackOrderCurrencyCode} ${record.callbackOrderDiscount}`,
    'Marketing tags': (record) => {
      if (record.callbackMarketingTags) {
        return (
          <pre>{JSON.stringify(record.callbackMarketingTags, null, 2)}</pre>
        )
      }
    },
    'Created at': (record) => formatDatetimeSec(record.callbackCreatedDatetime),
    'Meta IP': 'callbackMetaIp',
    'Meta URL': 'callbackMetaUrl',
    'Meta UA': 'callbackMetaUserAgent',
    'Conversion message': 'callbackConversionError',
    'Conversion details': (record) => {
      return (
        <div>
          <div>
            {record.callbackCreateConversionReport && (
              <pre>
                {JSON.stringify(record.callbackCreateConversionReport, null, 2)}
              </pre>
            )}
          </div>
          <div>
            {record.callbackUpdateConversionReport && (
              <pre>
                {JSON.stringify(record.callbackUpdateConversionReport, null, 2)}
              </pre>
            )}
          </div>
        </div>
      )
    },
  },
})

export default function IndexPage(props) {
  return (
    <Layout>
      <Router>
        <Redirect from="/" to="/contracts" noThrow={true} />

        <AdminUsers.List path="/admin_users" />
        <AdminUsers.Show path="/admin_users/:uuid" />
        <AdminUsers.New path="/admin_users/new" />
        <AdminUsers.Edit path="/admin_users/:uuid/edit" />

        <Publishers.List path="/publishers" />
        <Publishers.Show path="/publishers/:uuid" />
        <Publishers.New path="/publishers/new" />
        <Publishers.Edit path="/publishers/:uuid/edit" />

        <PublisherUsers.List path="/publisher_users" />
        <PublisherUsers.Show path="/publisher_users/:uuid" />
        <PublisherUsers.New path="/publisher_users/new" />
        <PublisherUsers.Edit path="/publisher_users/:uuid/edit" />

        <Contracts.List path="/contracts" />
        <Contracts.Show path="/contracts/:uuid" />
        <Contracts.New path="/contracts/new" />
        <Contracts.Edit path="/contracts/:uuid/edit" />

        <Advertisers.List path="/advertisers" />
        <Advertisers.Show path="/advertisers/:uuid" />
        <Advertisers.New path="/advertisers/new" />
        <Advertisers.Edit path="/advertisers/:uuid/edit" />

        <AdvertiserUsers.List path="/advertiser_users" />
        <AdvertiserUsers.Show path="/advertiser_users/:uuid" />
        <AdvertiserUsers.New path="/advertiser_users/new" />
        <AdvertiserUsers.Edit path="/advertiser_users/:uuid/edit" />

        <Articles.List path="/articles" />
        <Articles.Show path="/articles/:uuid" />
        <Articles.New path="/articles/new" />
        <Articles.Edit path="/articles/:uuid/edit" />

        <Settings.DirectCss path="/settings/direct-css" />
        <Settings.LoginCss path="/settings/login-css" />

        <ConversionRules.List path="/conversion_rules" />
        <ConversionRules.Show path="/conversion_rules/:uuid" />
        <ConversionRules.New path="/conversion_rules/new" />
        <ConversionRules.Edit path="/conversion_rules/:uuid/edit" />

        <Callbacks.List path="/callbacks" />
        <Callbacks.Show path="/callbacks/:uuid" />
      </Router>
    </Layout>
  )
}

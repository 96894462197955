import React from 'react'
import { LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'gatsby-theme-material-ui'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { defaultFormStyles } from './shared'
import { isEmail } from '../../services/utils'

const useStyles = makeStyles((theme) => ({
  form: {
    ...defaultFormStyles(theme),
  },
}))

export default function AdminUserForm(props) {
  const { isNew, onSubmit } = props
  const initialValues = props.initialValues || {}

  const classes = useStyles()

  return (
    <Formik
      initialValues={{
        email: initialValues.email || '',
        password: '',
        confirmPassword: '',
      }}
      validate={(values) => {
        const errors = {}
        if (!values.email) {
          errors.email = 'Required'
        } else if (!isEmail(values.email)) {
          errors.email = 'Invalid email address'
        } else if (isNew && !values.password) {
          errors.password = 'Required'
        } else if (
          values.password &&
          values.password !== values.confirmPassword
        ) {
          errors.confirmPassword = "Passwords don't match"
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form className={classes.form} acceptCharset="UTF-8">
          <Field
            component={TextField}
            name="email"
            type="email"
            label="Email"
          />
          <Field
            component={TextField}
            name="password"
            type="password"
            label={isNew ? 'Password' : 'New password'}
          />
          <Field
            component={TextField}
            name="confirmPassword"
            type="password"
            label={
              isNew ? 'Password confirmation' : 'New password confirmation'
            }
          />
          {isSubmitting && <LinearProgress />}
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {isNew ? 'Create Admin User' : 'Update Admin User'}
            </Button>
            <Button
              to={isNew ? '/admin_users' : `/admin_users/${initialValues.uuid}`}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

import React, { useState, useEffect } from 'react'
import { Snackbar } from '@material-ui/core'

export const Toast = (props) => {
  const { message, setMessage } = props

  const [shownMessage, setShownMessage] = useState('')

  useEffect(() => {
    if (message) {
      setShownMessage(message)
    }
  }, [message])

  return (
    <Snackbar
      open={!!message}
      message={shownMessage}
      autoHideDuration={5000}
      onClose={() => setMessage('')}
    />
  )
}

import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Button as MaterialButton,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/moment'
import { Title } from '../shared'
import * as API from '../../services/api'
import { navigateTo, pluralized } from '../../services/utils'

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableRow-root': {
      cursor: 'pointer',
    },
  },
  loadMore: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
}))

const perPage = 1000
const dateStorageKey = 'callbacks-date'

export default function ResourceList(props) {
  const { resource, title, fields } = props
  const classes = useStyles()

  const [records, setRecords] = useState(null)
  const [nextPage, setNextPage] = useState('')
  const [date, setDate] = useState(() => {
    const item = localStorage.getItem(dateStorageKey)
    if (item) {
      const storedDate = JSON.parse(item)
      if (storedDate.created === new Date().toISOString().substring(0, 10)) {
        return storedDate.value
      } else {
        return new Date()
      }
    } else {
      return new Date()
    }
  })
  const changeDate = (newDate) => {
    setDate(newDate)
    localStorage.setItem(
      dateStorageKey,
      JSON.stringify({
        value: newDate,
        created: new Date().toISOString().substring(0, 10),
      })
    )
  }

  useEffect(() => {
    if (records === null) {
      API.all(resource, {
        date: moment(date).format('YYYY-MM-DD'),
        perPage,
      }).then((response) => {
        if (response?.data) {
          setRecords(response.data.records)
          setNextPage(response.data.nextPage)
        }
      })
    }
  }, [resource, records, date])

  if (records === null) {
    return <CircularProgress />
  }

  const reloadData = (event) => {
    setRecords(null)
  }

  const loadMore = () => {
    const page = nextPage
    API.all(resource, { date, page, perPage }).then((response) => {
      if (response?.data) {
        setRecords([...records, ...response.data.records])
        setNextPage(response.data.nextPage)
      }
    })
  }

  return (
    <>
      <Title title={pluralized(title)}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            value={date}
            onChange={changeDate}
            onClose={reloadData}
            autoOk={true}
            format="DD/MM/YYYY"
          />
        </MuiPickersUtilsProvider>
      </Title>

      <TableContainer component={Paper}>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {Object.keys(fields).map((field) => (
                <TableCell key={field}>{field}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {records &&
              records.map((record) => (
                <TableRow
                  key={record.callbackUuid}
                  onClick={(event) => {
                    if (!event.target.closest('a, button')) {
                      navigateTo(event, `/${resource}/${record.callbackUuid}`)
                    }
                  }}
                >
                  {Object.keys(fields).map((field) =>
                    typeof fields[field] === 'string' ? (
                      <TableCell key={`${record.uuid}/${field}`}>
                        {record[fields[field]]}
                      </TableCell>
                    ) : (
                      <TableCell key={`${record.uuid}/${field}`}>
                        {fields[field](record)}
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {nextPage && (
        <div className={classes.loadMore}>
          <MaterialButton
            color="primary"
            variant="contained"
            onClick={loadMore}
          >
            Load more
          </MaterialButton>
        </div>
      )}
    </>
  )
}

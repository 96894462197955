import React, { useState, useEffect } from 'react'
import {
  Button as MaterialButton,
  LinearProgress,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'gatsby-theme-material-ui'
import { Formik, Form, Field, FastField, FieldArray } from 'formik'
import { TextField, CheckboxWithLabel } from 'formik-material-ui'
import { defaultFormStyles, SelectField } from './shared'
import * as API from '../../services/api'

const contractSort = (a, b) => a.code - b.code

const defaultRuleValues = {
  triggers: [
    {
      key: 'itemName',
      rule: 'equals',
      value: '',
    },
  ],
  action: {
    key: 'itemPayout',
    rule: 'set',
    value: '',
  },
}

const triggerKeyOptions = [
  { label: 'Item name', value: 'itemName' },
  { label: 'Item variant', value: 'itemVariant' },
  { label: 'Item url', value: 'itemProductUrl' },
  { label: 'Item category', value: 'itemCategoryName' },
  { label: 'Coupon code', value: 'callbackOrderCouponCode' },
  { label: 'Goal ID', value: 'callbackGoalId' },
  { label: 'Goal name', value: 'callbackGoalName' },
  { label: 'Marketing tag', value: 'callbackMarketingTag' },
  {
    label: 'Marketing tag value (after HL click)',
    value: 'callbackMarketingTagValue0',
  },
  {
    label: 'Marketing tag value (after HL click + 1 day before HL click)',
    value: 'callbackMarketingTagValue1',
  },
  {
    label: 'Marketing tag value (after HL click + 7 days before HL click)',
    value: 'callbackMarketingTagValue7',
  },
  {
    label: 'Marketing tag value (after HL click + 14 days before HL click)',
    value: 'callbackMarketingTagValue14',
  },
  {
    label: 'Marketing tag value (after HL click + 30 days before HL click)',
    value: 'callbackMarketingTagValue30',
  },
]

const triggerRuleOptions = [
  { label: 'Equals', value: 'equals' },
  { label: 'Contains', value: 'contains' },
]

const actionKeyOptions = [
  { label: 'Item payout', value: 'itemPayout' },
  { label: 'Total payout', value: 'totalPayout' },
]

const actionRuleOptions = [
  { label: 'Set to', value: 'set' },
  { label: 'Reject', value: 'reject' },
]

const useStyles = makeStyles((theme) => ({
  form: {
    ...defaultFormStyles(theme),

    '& .MuiFormControl-root': {
      width: '100%',
    },

    '& .MuiFormControlLabel-root': {
      '& .MuiFormControlLabel-label': {
        paddingTop: theme.spacing(1),
      },
    },

    '& .checkbox-container': {
      marginTop: -16,
    },

    '& .MuiAutocomplete-root': {
      marginTop: 32,
    },

    '& .contract-info': {
      marginTop: 8,
      marginBottom: -8,
    },

    '& .swap-trigger-btn': {
      display: 'block',
      height: 16,
      padding: 0,
      margin: '0 auto',

      '&.hidden': {
        opacity: 0,
        pointerEvents: 'none',
      },
    },

    '& .remove-trigger-btn': {
      minWidth: 36,
      marginTop: 8,
    },
  },
  fieldset: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  fieldrow: {
    '& .MuiGrid-root': {
      marginLeft: 4,
      marginRight: 4,
    },
  },
  hint: {
    paddingLeft: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderLeft: `2px solid ${theme.palette.info.light}`,
    color: theme.palette.grey[700],
  },
  autocompleteSelect: {
    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },

    '& .MuiAutocomplete-popupIndicator': {
      marginTop: 0,
      marginRight: 0,
    },

    '& .MuiButtonBase-root': {
      position: 'relative',
      top: 16,
    },
  },
}))

export default function ConversionRuleForm(props) {
  const { isNew, onSubmit } = props

  const initialValues = props.initialValues || {}
  const initialRuleValue = { ...defaultRuleValues, ...initialValues.rule }

  const classes = useStyles()

  const [contracts, setContracts] = useState(null)

  useEffect(() => {
    if (!contracts) {
      API.form('conversion_rules').then((response) => {
        if (response?.data?.records) {
          setContracts(response.data.records)
        }
      })
    }
  }, [contracts])

  if (contracts === null) {
    return <CircularProgress />
  }

  return (
    <Formik
      initialValues={{
        contractUuid: initialValues.contractUuid,
        name: initialValues.name || '',
        isActive: true,
        rule: initialRuleValue,
      }}
      validate={(values) => {
        const errors = {}
        if (!values.name) {
          errors.name = 'Required'
        } else if (!values.contractUuid) {
          errors.contractUuid = 'Required'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue, submitForm, isSubmitting }) => {
        const contract = contracts.find(
          (contract) =>
            contract.uuid ===
            (values.contractUuid || initialValues.contractUuid)
        )

        return (
          <Form className={classes.form} acceptCharset="UTF-8">
            <Grid container justify="center" alignItems="center">
              <Grid item sm={12} md={9}>
                <FastField
                  component={TextField}
                  variant="outlined"
                  size="small"
                  name="name"
                  label="Rule name"
                />

                <div className="checkbox-container">
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="isActive"
                    onChange={(event) =>
                      setFieldValue('isActive', event.target.checked)
                    }
                    Label={{
                      label: 'Active',
                    }}
                  />
                </div>

                <Autocomplete
                  className={classes.autocompleteSelect}
                  name="contractUuid"
                  size="small"
                  options={contracts
                    ?.sort(contractSort)
                    .map((contract) => contract.uuid)}
                  getOptionLabel={(value) => {
                    const contract = contracts.find(
                      (contract) => contract.uuid === value
                    )
                    return contract?.code || ''
                  }}
                  defaultValue={props.initialValues?.contractUuid}
                  onChange={(event, value) => {
                    setFieldValue('contractUuid', value)
                  }}
                  renderInput={(inputProps) => (
                    <Field
                      component={TextField}
                      variant="outlined"
                      size="small"
                      name="contractUuid"
                      label="Campaign"
                      {...inputProps}
                    />
                  )}
                />

                <div className="contract-info">
                  {contract && (
                    <>
                      <div>
                        Commission model: {contract.data.commissionModel}
                      </div>
                      {contract.data.commissionModel === 'CPS' ? (
                        <div>
                          Default commission:{' '}
                          {contract.data.commissionPercentage}%
                        </div>
                      ) : (
                        <div>
                          Default commission: {contract.data.currency}{' '}
                          {contract.data.commissionPrice}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Grid>
            </Grid>

            <Typography variant="h6" className={classes.fieldset}>
              Conditions
            </Typography>
            <Typography variant="body2" className={classes.hint}>
              Each condition must be met for action to apply.
              <br />
              "Marketing tag value" conditions only work with matched "Marketing
              tag" condition.
              <br />
              Wildcard "*" can be used to match any string.
            </Typography>
            <FieldArray name="rule.triggers">
              {({ swap, remove, push }) => (
                <>
                  {values.rule.triggers.map((trigger, index) => (
                    <Grid
                      key={index}
                      container
                      alignItems="center"
                      justify="center"
                      className={classes.fieldrow}
                    >
                      <Grid item sm={1}>
                        <MaterialButton
                          onClick={() => swap(index, index - 1)}
                          className={`swap-trigger-btn ${
                            index > 0 ? 'visible' : 'hidden'
                          }`}
                        >
                          ˄
                        </MaterialButton>
                        <MaterialButton
                          onClick={() => swap(index, index + 1)}
                          className={`swap-trigger-btn ${
                            index < values.rule.triggers.length - 1
                              ? 'visible'
                              : 'hidden'
                          }`}
                        >
                          ˅
                        </MaterialButton>
                      </Grid>
                      <Grid item sm={3}>
                        <SelectField
                          name={`rule.triggers.${index}.key`}
                          label="Trigger key"
                          initialValue={triggerKeyOptions[0].value}
                          values={triggerKeyOptions}
                        />
                      </Grid>
                      <Grid item sm={3}>
                        <SelectField
                          name={`rule.triggers.${index}.rule`}
                          label="Rule"
                          initialValue={triggerRuleOptions[0].value}
                          values={triggerRuleOptions}
                        />
                      </Grid>
                      <Grid item sm={3}>
                        <FastField
                          component={TextField}
                          variant="outlined"
                          size="small"
                          name={`rule.triggers.${index}.value`}
                          label="Value"
                        />
                      </Grid>
                      <Grid item sm={1}>
                        {values.rule.triggers.length > 1 && (
                          <MaterialButton
                            onClick={() => remove(index)}
                            className="remove-trigger-btn"
                          >
                            ✕
                          </MaterialButton>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                  <Grid
                    container
                    alignItems="center"
                    justify="flex-start"
                    className={classes.fieldrow}
                  >
                    <Grid item md={12}>
                      <MaterialButton
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          push({
                            ...defaultRuleValues.triggers[0],
                          })
                        }
                      >
                        Add condition
                      </MaterialButton>
                    </Grid>
                  </Grid>
                </>
              )}
            </FieldArray>

            <Typography variant="h6" className={classes.fieldset}>
              Action
            </Typography>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.fieldrow}
            >
              <Grid item md={3}>
                <SelectField
                  name="rule.action.key"
                  label="Action key"
                  initialValue={initialRuleValue?.actionKey}
                  values={actionKeyOptions}
                />
              </Grid>
              <Grid item md={3}>
                <SelectField
                  name="rule.action.rule"
                  label="Rule"
                  initialValue={initialRuleValue?.actionRule}
                  values={actionRuleOptions}
                />
              </Grid>
              <Grid item md={3}>
                <Field
                  component={TextField}
                  variant="outlined"
                  size="small"
                  name="rule.action.value"
                  label="Value"
                  disabled={values.rule.actionRule === 'reject'}
                />
              </Grid>
              <Grid item md={1} />
            </Grid>

            <br />
            {isSubmitting && <LinearProgress />}
            <div className={classes.formActions}>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {isNew ? 'Create New Rule' : 'Update Rule'}
              </Button>
              <Button
                to={
                  isNew
                    ? '/conversion_rules'
                    : `/conversion_rules/${initialValues.uuid}`
                }
              >
                Cancel
              </Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

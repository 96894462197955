import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Snackbar, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiSnackbarContent-root': {
      maxHeight: '90vh',
      overflow: 'auto',
    },
  },

  close: {
    padding: theme.spacing(0.5),
  },
}))

export const PatientToast = (props) => {
  const { message, setMessage } = props

  const [shownMessage, setShownMessage] = useState('')

  const classes = useStyles()

  useEffect(() => {
    if (message) {
      setShownMessage(message)
    }
  }, [message])

  return (
    <Snackbar
      open={!!message}
      message={shownMessage}
      className={classes.container}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          className={classes.close}
          onClick={() => {
            setMessage('')
          }}
        >
          <CloseIcon />
        </IconButton>
      }
    />
  )
}

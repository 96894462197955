import React, { useState, useEffect } from 'react'
import { CircularProgress, LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'gatsby-theme-material-ui'
import { Formik, Form, Field } from 'formik'
import {
  TextField,
  SimpleFileUpload,
  CheckboxWithLabel,
} from 'formik-material-ui'
import { defaultFormStyles, SelectField, RichEditor } from './shared'
import * as API from '../../services/api'

const categoryOptions = [
  { label: 'CMS article', value: 'cms' },
  { label: 'Help article', value: 'help' },
]

const slugLabel = (category) => {
  switch (category) {
    case 'cms':
      return 'CMS name'
    case 'help':
      return 'Article slug'
    default:
      return 'Article slug'
  }
}

const useStyles = makeStyles((theme) => ({
  form: {
    ...defaultFormStyles(theme),

    '& .MuiCheckbox-root': {
      margin: theme.spacing(1),
    },

    '& .hint': {
      padding: '0 8px',
      marginTop: -4,
      marginBottom: 8,
      color: '#dd2200',
      fontSize: 13,
    },
  },

  bodyContainer: {
    position: 'relative',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),

    '& > div': {
      border: '1px solid #919191',
      borderRadius: 3,
      marginTop: theme.spacing(3),

      '& .public-DraftEditor-content': {
        minHeight: 300,
      },
    },
  },
}))

export default function Article(props) {
  const { isNew, onSubmit } = props
  const initialValues = props.initialValues || {}

  const classes = useStyles()

  const [articles, setArticles] = useState(null)

  useEffect(() => {
    if (articles === null) {
      API.all('articles', {
        page: 1,
        perPage: Number.MAX_SAFE_INTEGER,
      }).then((response) => {
        setArticles(response?.data?.records)
      })
    }
  }, [articles])

  if (articles === null) {
    return <CircularProgress />
  }

  const takenSlugs = articles
    .filter((article) => article.uuid !== initialValues.uuid)
    .map((article) => article.slug.toLowerCase())

  return (
    <Formik
      initialValues={{
        isPublished: !!initialValues.isPublished || false,
        isMajor: !!initialValues.isMajor || false,
        isMinor: !!initialValues.isMinor || false,
        isHelp: !!initialValues.isHelp || false,
        category: initialValues.category || 'cms',
        order: initialValues.order || 99,
        logoFile: initialValues.logoFile || '',
        slug: initialValues.slug || '',
        title: initialValues.title || '',
        subtitle: initialValues.subtitle || '',
        body: initialValues.body || '',
      }}
      validate={(values) => {
        const errors = {}
        if (!values.slug) {
          errors.slug = 'Required'
        } else if (takenSlugs.includes(values.slug.toLowerCase())) {
          errors.slug = 'Taken'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        if (values.logoFile) {
          const data = new FormData()
          data.append('file', values.logoFile)
          const response = await API.upload('articles', data)
          values.logoUrl = response.data.url
        }

        await onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue, submitForm, isSubmitting }) => (
        <Form className={classes.form} acceptCharset="UTF-8">
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="isPublished"
            onChange={(event) =>
              setFieldValue('isPublished', event.target.checked)
            }
            Label={{ label: 'Published' }}
          />
          <SelectField
            name="category"
            label="Category"
            initialValue={initialValues.category}
            values={categoryOptions}
          />
          <br />

          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="isMajor"
            onChange={(event) => setFieldValue('isMajor', event.target.checked)}
            Label={{ label: 'Show on major platforms list' }}
          />
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="isMinor"
            onChange={(event) => setFieldValue('isMinor', event.target.checked)}
            Label={{ label: 'Show on minor platforms list' }}
          />
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="isHelp"
            onChange={(event) => setFieldValue('isHelp', event.target.checked)}
            Label={{ label: 'Show in help dropdown list' }}
          />
          <Field
            component={TextField}
            type="number"
            name="order"
            label="Order"
          />
          <Field
            component={TextField}
            name="slug"
            type="text"
            label={slugLabel(values.category)}
          />
          <Field
            component={SimpleFileUpload}
            name="logoFile"
            type="file"
            label="Major CMS logo"
          />
          <Field
            component={TextField}
            name="title"
            type="text"
            label="Headline"
          />
          <Field
            component={TextField}
            name="subtitle"
            type="text"
            label="Subheadline"
          />
          <div className={classes.bodyContainer}>
            <span className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
              Article body
            </span>
            <RichEditor
              initialValue={initialValues.body}
              onChange={(value) => setFieldValue('body', value)}
            />
          </div>
          <div className="hint">
            Use shift+enter to insert newlines in scripts and lists.
            <br />
            CMS article constants:
            <br />
            SCRIPTMANAGER_URL - url to scriptmanager advertiser script
          </div>
          {isSubmitting && <LinearProgress />}
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {isNew ? 'Create Article' : 'Update Article'}
            </Button>
            <Button
              to={isNew ? '/articles' : `/articles/${initialValues.uuid}`}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

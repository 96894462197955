import React, { useState, useEffect } from 'react'
import { CircularProgress, LinearProgress } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'gatsby-theme-material-ui'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { defaultFormStyles } from './shared'
import { isEmail } from '../../services/utils'
import * as API from '../../services/api'

const useStyles = makeStyles((theme) => ({
  form: {
    ...defaultFormStyles(theme),
  },
}))

export default function PublisherUserForm(props) {
  const { isNew, onSubmit } = props
  const initialValues = props.initialValues || {}
  const classes = useStyles()

  const [publishers, setPublishers] = useState(null)

  useEffect(() => {
    if (!publishers) {
      API.all('publishers', {
        page: 1,
        perPage: Number.MAX_SAFE_INTEGER,
      }).then((response) => {
        if (response?.data?.records) {
          setPublishers(response.data.records)
        }
      })
    }
  }, [publishers])

  if (publishers === null) {
    return <CircularProgress />
  }

  return (
    <Formik
      initialValues={{
        publisherUuid: initialValues.publisherUuid || '',
        name: initialValues.name || '',
        email: initialValues.email || '',
        password: '',
        confirmPassword: '',
      }}
      validate={(values) => {
        const errors = {}
        if (!values.email) {
          errors.email = 'Required'
        } else if (!isEmail(values.email)) {
          errors.email = 'Invalid email address'
        } else if (!values.name) {
          errors.name = 'Required'
        } else if (isNew && !values.password) {
          errors.password = 'Required'
        } else if (
          values.password &&
          values.password !== values.confirmPassword
        ) {
          errors.confirmPassword = "Passwords don't match"
        } else if (!values.publisherUuid) {
          errors.publisherUuid = 'Required'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue, submitForm, isSubmitting }) => (
        <Form className={classes.form} acceptCharset="UTF-8">
          <Autocomplete
            name="publisherUuid"
            label="Publisher"
            options={publishers?.map((publisher) => publisher.uuid)}
            getOptionLabel={(value) => {
              const publisher = publishers.find(
                (publisher) => publisher.uuid === value
              )
              const adminStatus = publisher.isAdmin ? '(Admin) ' : ''
              const name = publisher.name || 'Unnamed publisher'
              return adminStatus + name
            }}
            defaultValue={initialValues?.publisherUuid}
            onChange={(event, value) => {
              setFieldValue('publisherUuid', value)
            }}
            renderInput={(inputProps) => (
              <Field
                component={TextField}
                name="publisherUuid"
                label="Publisher"
                {...inputProps}
              />
            )}
          />
          <Field
            component={TextField}
            name="name"
            type="text"
            label="Full name"
          />
          <Field
            component={TextField}
            name="email"
            type="email"
            label="Email"
          />
          <Field
            component={TextField}
            name="password"
            type="password"
            label={isNew ? 'Password' : 'New password'}
          />
          <Field
            component={TextField}
            name="confirmPassword"
            type="password"
            label={
              isNew ? 'Password confirmation' : 'New password confirmation'
            }
          />
          {isSubmitting && <LinearProgress />}
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {isNew ? 'Create User' : 'Update User'}
            </Button>
            <Button
              to={
                isNew
                  ? '/publisher_users'
                  : `/publisher_users/${initialValues.uuid}`
              }
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

import { getData, getName } from 'country-list'

export const countryCodes = getData().map((data) => data.code)

export const countryLabels = getData().reduce((acc, data) => {
  acc[data.code] = data.name
  return acc
}, {})

export const getCountryName = (code) => getName(code || '')
